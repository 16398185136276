import React, { Fragment, useState } from 'react';
import BroadcastChart, { getTimeRangeInterval } from './BroadcastChart';
import useUserInfo from '../../hooks/use-user-info';
import PageTitle from 'features/layout/PageTitle';
import { useQuery } from 'react-query';
import api from 'services/api';
import Select, { Theme } from 'react-select';
import { TimeRange } from 'shared-types';
import { computeTotalsFromOverview } from 'utils/broadcastHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CardTracksMonitoringInfo } from './CardTracksMonitoringInfo';
import { Link } from 'react-router-dom';
import Card from './Card';
import RightAmountWithMargin from '../../components/broadcast/RightsAmountWithMargin';
import { RadioHeader, StreamHeader, TvHeader } from 'components/broadcast/BroadcastHeader';
import { BroadcastContent } from 'components/broadcast/BroadcastContent';
import { CountUp } from 'utils/countUp';
import { CardRightsTotal } from './CardRightsTotal';
import { activeEmptyState } from '../../App';
import Squeleton from '../../components/Squeleton';
import useRights from 'hooks/use-rights';
import useAvailableCashOutAmount from 'hooks/use-available-cash-out-amount';
import Loader from '../../components/Loader';

// Custom design for Select
const customColors = {
  primary: '#003cff',
  primary75: '#99b1ff',
  primary50: '#e6ecff',
  primary25: '#f2f5ff',
  neutral20: '#d1d5db',
};

type StateType = {
  isFocused: boolean;
};

const getControlClassNames = (state: StateType) =>
  state.isFocused ? '!ring-4 !ring-primary-200/80 !border-primary' : '';

const customTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    ...customColors,
  },
});

interface SelectOption<T> {
  value: T;
  label: string;
}

const timeRangeOptions: SelectOption<TimeRange>[] = [
  { value: 'all_time', label: 'Depuis le début de mon inscription' },
  { value: 'last_7_days', label: '7 derniers jours' },
  { value: 'last_30_days', label: '30 derniers jours' },
  // { value: 'last_365_days', label: 'Depuis 1 an' },
];

const initialChannelNameOptions: Array<SelectOption<string>> = [{ value: '', label: 'Toutes les sources' }];

function DashboardPage() {
  const [timeRange, setTimeRange] = useState<SelectOption<TimeRange>>(timeRangeOptions[0]);
  const [streamChannelName, setStreamChannelName] = useState<SelectOption<string>>(initialChannelNameOptions[0]);
  const [radioChannelName, setRadioChannelName] = useState<SelectOption<string>>(initialChannelNameOptions[0]);
  const [tvChannelName, setTvChannelName] = useState<SelectOption<string>>(initialChannelNameOptions[0]);

  const from = getTimeRangeInterval(timeRange.value).lowerBound?.unix();
  const to = getTimeRangeInterval(timeRange.value).upperBound?.unix();
  const {
    userInfo: { userName, subscription, trackCount, email, registerTs },
    isLoading: isLoadingUserInfo,
    isError: isErrorUserInfo,
  } = useUserInfo();

  const {
    data: broadcastOverview,
    isLoading: isLoadingBroadcast,
    isError: isErrorBroadcast,
  } = useQuery(
    ['/api/broadcast-overview', timeRange],
    () =>
      api.fetchBroadcastOverview({
        from,
        to,
      }),
    { staleTime: Infinity },
  );

  const { rights, isLoadingRights, isErrorRights } = useRights(timeRange.value);
  const { amountToBeCashedOut, totalCashedOut, isLoadingAmountToBeCashedOut, isErrorAmountToBeCashedOut } =
    useAvailableCashOutAmount();

  const {
    data: streamStats,
    isLoading: isLoadingStreamStats,
    isError: isErrorStreamStats,
  } = useQuery(
    ['/api/stream-stats', timeRange, streamChannelName],
    () =>
      api.fetchBroadcastStatReport({
        from: getTimeRangeInterval(timeRange.value).lowerBound?.unix(),
        to: getTimeRangeInterval(timeRange.value).upperBound.unix(),
        media: 'streams',
        mediaName: streamChannelName.value,
      }),
    { staleTime: Infinity },
  );

  const {
    data: radioStats,
    isLoading: isLoadingRadioStats,
    isError: isErrorRadioStats,
  } = useQuery(
    ['/api/radio-stats', timeRange, radioChannelName],
    () =>
      api.fetchBroadcastStatReport({
        from: getTimeRangeInterval(timeRange.value).lowerBound?.unix(),
        to: getTimeRangeInterval(timeRange.value).upperBound.unix(),
        media: 'radio',
        mediaName: radioChannelName.value,
      }),
    { staleTime: Infinity },
  );

  const {
    data: tvStats,
    isLoading: isLoadingTvStats,
    isError: isErrorTvStats,
  } = useQuery(
    ['/api/tv-stats', timeRange, tvChannelName],
    () =>
      api.fetchBroadcastStatReport({
        from: getTimeRangeInterval(timeRange.value).lowerBound?.unix(),
        to: getTimeRangeInterval(timeRange.value).upperBound.unix(),
        media: 'tv',
        mediaName: tvChannelName.value,
      }),
    { staleTime: Infinity },
  );

  const streamTotal = (streamStats || []).reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);
  const radioTotal = (radioStats || []).reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);
  const tvTotal = (tvStats || []).reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);
  const totalBroadcastsFromOverview = computeTotalsFromOverview(broadcastOverview);
  const totalRights = rights && rights.total;
  const weightPercentage = 0.17528;
  const streamChannelNameOptions = [...initialChannelNameOptions];
  const radioChannelNameOptions = [...initialChannelNameOptions];
  const tvChannelNameOptions = [...initialChannelNameOptions];

  const streamedPlatform = (broadcastOverview?.streams || []).map((s) => ({ name: s.platform, count: s.count }));
  streamChannelNameOptions.push(
    ...streamedPlatform.map((v) => ({
      value: v.name,
      label: `${v.name} (${v.count.toLocaleString('fr-FR', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: 1,
      })})`,
    })),
  );

  const liveBroadcastRadioChannelName = (broadcastOverview?.liveBroadcasts || [])
    .filter((lb) => lb.type === 'radio')
    .flatMap((lb) => lb.channels);
  radioChannelNameOptions.push(
    ...liveBroadcastRadioChannelName.map((v) => ({
      value: v.name,
      label: `${v.name} (${v.count.toLocaleString('fr-FR', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: 1,
      })})`,
    })),
  );
  const liveBroadcastTvChannelName = (broadcastOverview?.liveBroadcasts || [])
    .filter((lb) => lb.type === 'tv')
    .flatMap((lb) => lb.channels);
  tvChannelNameOptions.push(
    ...liveBroadcastTvChannelName.map((v) => ({
      value: v.name,
      label: `${v.name} (${v.count.toLocaleString('fr-FR', {
        notation: 'compact',
        compactDisplay: 'short',
        maximumFractionDigits: 1,
      })})`,
    })),
  );

  const isLoadingStats = isLoadingStreamStats || isLoadingRadioStats || isLoadingTvStats;
  const isErrorStats = isErrorStreamStats || isErrorRadioStats || isErrorTvStats;
  const isLoading = isLoadingUserInfo || isLoadingStats || isLoadingBroadcast;
  const isError = isErrorUserInfo || isErrorStats || isErrorBroadcast;

  const handleChangeTimeRange = (tr: any) => {
    setStreamChannelName(initialChannelNameOptions[0]);
    setRadioChannelName(initialChannelNameOptions[0]);
    setTvChannelName(initialChannelNameOptions[0]);
    setTimeRange(tr);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const newUser = subscription === undefined || !["active","trialing"].includes(subscription.status);
  // const newUser = true

  return (
    <>
      <PageTitle title="Tableau de bord" />
      <>
        <div className="mx-auto flex w-full max-w-3xl flex-col space-y-6">
          <h1 className="h4 text-gray-800">Tableau de bord</h1>
          <div className="space-y-3">
            <div className="w-full">
              <CardTracksMonitoringInfo />
              <div role="list" className="mb-6 divide-y divide-gray-100 rounded-b bg-green-600 px-6 text-white shadow">
                <div className="flex justify-between gap-x-6 py-6">
                  <div className="flex min-w-0 gap-x-4">
                    <FontAwesomeIcon
                      icon={icon({ name: 'hand-holding-dollar', family: 'sharp', style: 'light' })}
                      className="h-12 w-12 flex-none text-green-100"
                    />
                    {activeEmptyState ? (
                      <div className="min-w-0 flex-auto">
                        <p className="mb-3 !pb-0 font-semibold leading-6 text-white">
                          Aucune avance disponible pour le moment...
                        </p>
                        <p className="text-sm leading-5 text-green-100">
                          C'est normal ! Vous venez de vous inscrire à RightsNow! :).
                          {/*                          <a className="text-green-100 underline hover:text-white">*/}
                          {/*  Prévenez-moi par email lors des premiers gains générés.*/}
                          {/*</a>*/}
                        </p>
                      </div>
                    ) : (
                      <div className="min-w-0 flex-auto">
                        {!isLoadingAmountToBeCashedOut &&
                          !isErrorAmountToBeCashedOut &&
                          totalCashedOut !== undefined &&
                          totalCashedOut > 0 && (
                            <>
                              <p className="!pb-0 font-semibold leading-6 text-white">Demande déjà en cours</p>
                              <p className="text-sm leading-5 text-green-100">
                                Pour le moment, vous ne pouvez demander qu'une seule avance à la fois. Une fois l'avance
                                soldée, vous pourrez en demander une nouvelle :)
                              </p>
                            </>
                          )}
                        {!isLoadingAmountToBeCashedOut &&
                          !isErrorAmountToBeCashedOut &&
                          totalCashedOut !== undefined &&
                          totalCashedOut === 0 &&
                          amountToBeCashedOut !== undefined &&
                          amountToBeCashedOut < 10 && (
                            <>
                              <p className="!pb-0 font-semibold leading-6 text-white">Droits générés insuffisants</p>
                              <p className="text-sm leading-5 text-green-100">
                                Vous n'avez pas encore généré assez de droits pour demander une avance Sacem à RightsNow!
                                (il est nécessaire d'avoir généré au moins 10€).
                              </p>
                            </>
                          )}
                        {!isLoadingAmountToBeCashedOut &&
                          !isErrorAmountToBeCashedOut &&
                          totalCashedOut !== undefined &&
                          totalCashedOut === 0 &&
                          amountToBeCashedOut !== undefined &&
                          amountToBeCashedOut >= 10 && (
                            <>
                              <p className="!pb-0 font-semibold leading-6 text-white">Avance disponible</p>
                              <p className="text-sm leading-5 text-green-100">
                                Montant de l'avance potentielle que vous pouvez demander à RightsNow!
                              </p>
                              <Link
                                to="/cash-out-request"
                                className="mt-2.5 inline-flex items-center rounded-md bg-transparent px-6 py-2 text-sm leading-5 !text-white ring-1 ring-white transition-all hover:ring-white/70"
                              >
                                Demander une avance{' '}
                                <FontAwesomeIcon
                                  icon={icon({ name: 'arrow-right', family: 'sharp', style: 'light' })}
                                  className="ml-1 h-3 w-3 flex-none align-middle"
                                />
                              </Link>
                            </>
                          )}
                      </div>
                    )}
                  </div>
                  {!activeEmptyState &&
                  !isLoadingAmountToBeCashedOut &&
                  amountToBeCashedOut !== undefined &&
                  totalCashedOut !== undefined ? (
                    <>
                      {totalCashedOut === 0 && amountToBeCashedOut > 10 && (
                        <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                          <CountUp value={amountToBeCashedOut} type="currency" className="text-green-100" />
                        </div>
                      )}
                    </>
                  ) : !isLoadingAmountToBeCashedOut && amountToBeCashedOut === undefined ? (
                    <>Montant non disponible</>
                  ) : (
                    <Squeleton color="white" />
                  )}
                </div>
              </div>
            </div>
            {!activeEmptyState && <CardRightsTotal />}
          </div>

          {!newUser && (
            <>
              <h1 className="h4 text-gray-800">Diffusions</h1>
              <div className="w-full">
                {/*Head of cards*/}
                <div className="mb-6 flex flex-col items-baseline justify-between space-y-4 text-gray-800 md:flex-row">
                  <div className="text-xl">
                    Résumé
                    <Select
                      onChange={handleChangeTimeRange}
                      className="react-select ml-2 inline-flex"
                      theme={customTheme}
                      classNames={{ control: getControlClassNames }}
                      options={timeRangeOptions}
                      isSearchable={false}
                      value={timeRange}
                    />
                  </div>
                  <div className="items-center md:inline-flex">
                    <span>Total des droits générés :&nbsp;</span>
                    <strong>
                      {/* Chargement des données */}
                      {(isLoadingRights || isErrorRights) && <Loader size="xs" label="" />}

                      {/* Si tout est ok, on affiche les données */}
                      {!isLoadingRights && !isErrorRights && totalRights && (
                        <RightAmountWithMargin
                          amount={totalRights}
                          compact={false}
                          weightPercentage={weightPercentage}
                        />
                      )}
                    </strong>
                  </div>
                </div>

                {/*New factorized cards*/}
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
                  {/*Streams Card*/}
                  <Card
                    header={<StreamHeader />}
                    content={
                      <BroadcastContent
                        broadcastNumber={totalBroadcastsFromOverview.totalStreamBroadcast}
                        isLoadingBroadcast={isLoadingBroadcast && !isErrorBroadcast}
                        rightsAmount={rights?.streams ?? 0}
                        rightsMargin={weightPercentage}
                        isLoadingRights={isLoadingRights && !isErrorRights}
                        isLoading={isLoadingUserInfo && !isLoadingUserInfo}
                      />
                    }
                  />

                  {/*Radios Card*/}
                  <Card
                    header={<RadioHeader />}
                    content={
                      <BroadcastContent
                        broadcastNumber={totalBroadcastsFromOverview.totalRadioBroadcast}
                        isLoadingBroadcast={isLoadingBroadcast && !isErrorBroadcast}
                        rightsAmount={rights?.radio ?? 0}
                        rightsMargin={weightPercentage}
                        isLoadingRights={isLoadingRights && !isErrorRights}
                        isLoading={isLoadingUserInfo && !isLoadingUserInfo}
                        showUpdateSubscriptionLink={
                          !isLoadingUserInfo && !isErrorUserInfo && subscription?.formula === '1'
                        }
                      />
                    }
                  />

                  {/*TV Card*/}
                  <Card
                    header={<TvHeader />}
                    content={
                      <BroadcastContent
                        broadcastNumber={totalBroadcastsFromOverview.totalTvBroadcast}
                        isLoadingBroadcast={isLoadingBroadcast && !isErrorBroadcast}
                        rightsAmount={rights?.tv ?? 0}
                        rightsMargin={weightPercentage}
                        isLoadingRights={isLoadingRights && !isErrorRights}
                        isLoading={isLoadingUserInfo && !isLoadingUserInfo}
                        showUpdateSubscriptionLink={
                          !isLoadingUserInfo && !isErrorUserInfo && subscription?.formula !== '3'
                        }
                      />
                    }
                  />
                </div>
              </div>

              <div className="overflow-hidden rounded shadow-xl">
                <StreamHeader>
                  {!isLoadingBroadcast &&
                    !isErrorBroadcast &&
                    !isLoadingStreamStats &&
                    !isErrorStreamStats &&
                    totalBroadcastsFromOverview.totalStreamBroadcast > 0 && (
                      <div className="mt-2 items-center sm:mt-0 sm:inline-flex sm:space-x-2">
                        <span className="hidden sm:inline-block">Afficher</span>
                        <Select
                          onChange={setStreamChannelName}
                          className="react-select"
                          theme={customTheme}
                          classNames={{ control: getControlClassNames }}
                          options={streamChannelNameOptions}
                          isSearchable={true}
                          value={streamChannelName}
                        />
                        <FontAwesomeIcon
                          icon={duotone('arrow-right')}
                          className="hidden h-5 text-gray-600 sm:inline-block"
                        />
                        <div className="mt-2 sm:mt-0">
                          <span>Total : </span>
                          {!isLoadingStreamStats ? <strong>{streamTotal.toLocaleString()}</strong> : <>&mdash;</>}
                        </div>
                      </div>
                    )}
                </StreamHeader>
                <dl className="bg-white p-6 text-sm leading-6">
                  <BroadcastChart
                    totalBroadcast={totalBroadcastsFromOverview.totalStreamBroadcast}
                    stats={streamStats || []}
                    from={from}
                    to={to}
                    trackCount={trackCount}
                    isLoading={isLoadingStreamStats || isLoadingBroadcast}
                    isError={isErrorStreamStats || isErrorBroadcast}
                  />
                </dl>
              </div>

              {!isLoadingUserInfo && subscription?.formula !== '1' && (
                <div className="overflow-hidden rounded shadow-xl">
                  <RadioHeader>
                    {!isLoadingBroadcast &&
                      !isErrorBroadcast &&
                      !isLoadingRadioStats &&
                      !isErrorRadioStats &&
                      totalBroadcastsFromOverview.totalRadioBroadcast > 0 && (
                        <div className="mt-2 items-center sm:mt-0 sm:inline-flex sm:space-x-2">
                          <span className="hidden sm:inline-block">Afficher</span>
                          <Select
                            onChange={setRadioChannelName}
                            className="react-select"
                            theme={customTheme}
                            classNames={{ control: getControlClassNames }}
                            options={radioChannelNameOptions}
                            isSearchable={true}
                            value={radioChannelName}
                          />
                          <FontAwesomeIcon
                            icon={duotone('arrow-right')}
                            className="hidden h-5 text-gray-600 sm:inline-block"
                          />
                          <div className="mt-2 sm:mt-0">
                            <span>Total : </span>
                            {!isLoadingStreamStats ? <strong>{radioTotal.toLocaleString()}</strong> : <>&mdash;</>}
                          </div>
                        </div>
                      )}
                  </RadioHeader>
                  <dl className="bg-white p-6 text-sm leading-6">
                    <BroadcastChart
                      totalBroadcast={totalBroadcastsFromOverview.totalRadioBroadcast}
                      stats={radioStats || []}
                      from={from}
                      to={to}
                      trackCount={trackCount}
                      isLoading={isLoadingRadioStats || isLoadingBroadcast}
                      isError={isErrorRadioStats || isErrorBroadcast}
                    />
                  </dl>
                </div>
              )}

              {!isLoadingUserInfo && subscription?.formula === '3' && (
                <div className="overflow-hidden rounded shadow-xl">
                  <TvHeader>
                    {!isLoadingBroadcast &&
                      !isErrorBroadcast &&
                      !isLoadingTvStats &&
                      !isErrorTvStats &&
                      totalBroadcastsFromOverview.totalTvBroadcast > 0 && (
                        <div className="mt-2 items-center sm:mt-0 sm:inline-flex sm:space-x-2">
                          <span className="hidden sm:inline-block">Afficher</span>
                          <Select
                            onChange={setTvChannelName}
                            className="react-select"
                            theme={customTheme}
                            classNames={{ control: getControlClassNames }}
                            options={tvChannelNameOptions}
                            isSearchable={true}
                            value={tvChannelName}
                          />
                          <FontAwesomeIcon
                            icon={duotone('arrow-right')}
                            className="hidden h-5 text-gray-600 sm:inline-block"
                          />
                          <div className="mt-2 sm:mt-0">
                            <span>Total : </span>
                            {!isLoadingStreamStats ? <strong>{tvTotal.toLocaleString()}</strong> : <>&mdash;</>}
                          </div>
                        </div>
                      )}
                  </TvHeader>
                  <dl className="bg-white p-6 text-sm leading-6">
                    <BroadcastChart
                      totalBroadcast={totalBroadcastsFromOverview.totalTvBroadcast}
                      stats={tvStats || []}
                      from={from}
                      to={to}
                      trackCount={trackCount}
                      isLoading={isLoadingTvStats || isLoadingBroadcast}
                      isError={isErrorTvStats || isErrorBroadcast}
                    />
                  </dl>
                </div>
              )}

              <div className="mt-3 text-xs">
                <details>
                  <summary className="cursor-pointer text-primary hover:underline">
                    Hey ! Je devrais voir certaines statistiques mais elles ne s'affichent pas
                  </summary>
                  <p className="mt-3">
                    Ne vous inquiétez pas :). Nous collectons les données mais celles-ci peuvent avoir un peu de retard
                    au niveau de l'affichage (nous dépendons des plateformes de diffusion).
                  </p>
                  <p>
                    Il se peut également que l'un de vos titres soit diffusé durant une période mais que la plateforme
                    ne nous n'a pas encore communiqué les données.{' '}
                    <strong>Cette absence est automatiquement compensée lors de la prochaine collecte</strong>. Donc le
                    total est toujours fiable.
                  </p>
                  <p>
                    Si quelque chose vous semble anormal, n'hésitez pas à{' '}
                    <a href="https://www.rightsnow.fr/support/" target="_blank" rel="noreferrer">
                      nous contacter
                    </a>
                    .
                  </p>
                </details>
              </div>
            </>
          )}
        </div>
      </>
    </>
  );
}

export default DashboardPage;