import React from 'react';
import api from 'services/api';
import moment from 'moment';
import useUserInfo from 'hooks/use-user-info';
import StripeLogo from 'components/svg/StripeLogo';
import GenericError from 'components/GenericError';

const SubscriptionSettings = () => {
  const {
    userInfo: { subscription },
    isError,
  } = useUserInfo();

  const isCancelAtTsPassed =
    subscription?.cancelAtTs && new Date(subscription.cancelAtTs) < new Date();

  return (
    <div className="space-y-6">
      <div className="bg-white shadow-xl p-6 rounded">
        <StripeLogo className="w-16 mb-6 fill-[#635bff]" />
        <p>
          Votre abonnement est géré grâce à notre partenaire <strong>Stripe</strong>.
        </p>
        <p>Sur cet espace pouvez :</p>
        <ul className="list-disc ml-4 mb-6">
          <li>télécharger vos factures</li>
          <li>mettre à jour votre méthode de paiement</li>
          <li>modifier ou résilier votre abonnement</li>
        </ul>
        {!subscription?.cancelAtPeriodEnd && subscription?.currentPeriodEndTs && (
          <div className="message mb-6">
            <p>
              Votre abonnement sera renouvelé le{' '}
              <strong>{moment(subscription.currentPeriodEndTs).format('DD/MM/YYYY')}</strong>.
            </p>
          </div>
        )}
        {subscription?.cancelAtPeriodEnd && subscription?.cancelAtTs && !isCancelAtTsPassed && (
          <div className="message mb-6">
            <p>
              Votre abonnement sera terminé le{' '}
              <strong>{moment(subscription?.cancelAtTs || 0).format('DD/MM/YYYY')}</strong>.
            </p>
          </div>
        )}
        {subscription?.cancelAtPeriodEnd && subscription?.cancelAtTs && isCancelAtTsPassed && (
          <div className="message mb-6">
            <p>
              Votre abonnement est terminé depuis le{' '}
              <strong>{moment(subscription?.cancelAtTs || 0).format('DD/MM/YYYY')}</strong>.
            </p>
          </div>
        )}
        {isError && (
          <div className="mt-4">
            <GenericError />
          </div>
        )}
        <div>
          <a
            className="button"
            onClick={async () => {
              const res = await api.createCustomerPortalSession();
              window.location.href = res.data;
            }}
          >
            Gérer mon abonnement sur&nbsp;<strong>Stripe</strong>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSettings;
